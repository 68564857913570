import { trackUser } from '@api/tracking/client';
import type { TrackingContext } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';

export async function requestUpgrade({
  message,
  trackingContext,
  trackingPlacement,
}: {
  message: string;
  trackingContext: TrackingContext | undefined;
  trackingPlacement: string;
}) {
  await core().workspaces.requestUpgrade(message);
  trackUser({
    event: 'Request to upgrade sent',
    properties: {
      context: trackingContext,
      placement: trackingPlacement,
    },
  });
}
