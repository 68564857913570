import {
  type UserFeaturesT,
  WorkspaceRoleEnum,
} from '@mentimeter/http-clients';
import type { UserT } from './types';
import { userCache } from './internal-user-cache-wrapper';

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT_NAME === 'prod';
const isTest = process.env.NODE_ENV === 'test';

export const getMentiAcademyURL = (follow = ''): string => {
  if (!follow) {
    return '/academy';
  }

  const params = new URLSearchParams();

  params.append('follow', follow);

  return `/academy?${params.toString()}`;
};

export const getIsProUser = (userFeatures: UserFeaturesT | undefined) =>
  userFeatures?.type === 'pro';

export const getIsEnterpriseUser = (userFeatures: UserFeaturesT | undefined) =>
  userFeatures?.type === 'enterprise';

export const getIsBasicUser = (userFeatures: UserFeaturesT | undefined) =>
  userFeatures?.type === 'basic';

export const getIsPayingUser = (userFeatures: UserFeaturesT | undefined) =>
  getIsProUser(userFeatures) ||
  getIsBasicUser(userFeatures) ||
  getIsEnterpriseUser(userFeatures);

export const getHasProFeatures = (userFeatures: UserFeaturesT | undefined) =>
  getIsProUser(userFeatures) || getIsEnterpriseUser(userFeatures);

// free users in a paid workspace
export const getHasCustomColors = (userFeatures: UserFeaturesT | undefined) =>
  Boolean(userFeatures?.custom_colors);

export const getHasQuickForm = (userFeatures: UserFeaturesT | undefined) =>
  Boolean(userFeatures?.quick_form);

export const getHasSeveralAnswersEnabled = (
  userFeatures: UserFeaturesT | undefined,
) => Boolean(userFeatures?.several_answers);

export const getHasPrivateResultsEnabled = (
  userFeatures: UserFeaturesT | undefined,
) => Boolean(userFeatures?.private_results);

export const getHasModerationEnabled = (
  userFeatures: UserFeaturesT | undefined,
) => Boolean(userFeatures?.moderation_enabled);

export const getHasCustomThemesEnabled = (
  userFeatures: UserFeaturesT | undefined,
) => Boolean(userFeatures?.custom_themes_limit_user);

export const getHasCollaborationEnabled = (
  userFeatures: UserFeaturesT | undefined,
) => Boolean(userFeatures?.collaboration?.enabled);

export const getIsMemberLite = (user: UserT | undefined | null) =>
  user?.role === WorkspaceRoleEnum.MEMBER_LITE;

export const getIsMemberLiteInProWorkspace = (user: UserT | null | undefined) =>
  getIsMemberLite(user) && getIsProUser(user?.features);

export const isAuthorizedToInteractWithDevelopmentTools = () =>
  userCache?.email?.endsWith('@mentimeter.com') ||
  userCache?.email?.endsWith('@mentimeter.us') ||
  userCache?.email?.endsWith('@menti.com') ||
  (!isProduction && !isTest);
