import { getIsMemberLite, useUser } from '@mentimeter/user';
import { useState } from 'react';
import type { RequestUpgradeComponentProps } from './types';
import { RequestUpgradeModalContent } from './modal/RequestUpgradeModalContent';
import { requestUpgrade } from './requestUpgrade';

export function RequestUpgradeModal({
  title,
  description,
  trackingContext,
  trackingPlacement,
  onTrigger,
  children,
}: RequestUpgradeComponentProps) {
  const { user } = useUser();
  const isMemberLite = getIsMemberLite(user);
  const [show, setShow] = useState(false);

  if (!isMemberLite) return children;

  return (
    <RequestUpgradeModalContent
      title={title}
      description={description}
      open={show}
      onOpenChange={setShow}
      onRequestUpgrade={(message) =>
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement,
        })
      }
      onTrigger={onTrigger}
    >
      {children}
    </RequestUpgradeModalContent>
  );
}
