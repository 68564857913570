import React from 'react';
import type { BoxT } from '../Box';
import { Box } from '../Box';

export const PopoverFooter = ({ children, ...props }: BoxT) => (
  <Box
    width="100%"
    flexDirection={['column-reverse', 'row']}
    alignItems={['stretch', 'center']}
    justifyContent="flex-end"
    flexWrap="wrap"
    px="space4"
    pb="space4"
    gap="space2"
    {...props}
  >
    {children}
  </Box>
);
