import { Box } from '@mentimeter/ragnar-ui';
import React, { forwardRef } from 'react';
import type { ReactNode } from 'react';

interface Props {
  trigger: () => void;
  children: ReactNode;
}

export const CaptureInteraction = forwardRef(
  ({ children, trigger }: Props, ref) => (
    <Box
      ref={ref}
      alignSelf="stretch"
      alignItems="stretch"
      onClickCapture={(e) => {
        e.preventDefault();
        e.stopPropagation();

        trigger();
      }}
      onKeyDownCapture={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          e.stopPropagation();

          trigger();
        }
      }}
    >
      {children}
    </Box>
  ),
);
