import * as React from 'react';
import { CrossIcon } from '@mentimeter/ragnar-visuals';
import type { Extend } from '@mentimeter/ragnar-dsc';
import { Text } from '../Text';
import { Box } from '../Box';
import type { BoxT } from '../Box';
import { Clickable } from '../Clickable';
import { PopoverDismiss } from './PopoverDismiss';

interface PopoverHeaderProps extends BoxT {
  showDismiss?: boolean;
  dismissLabel?: string;
  onDismissClick?: () => void;
  popoverDismissStyles?: Extend;
  children?: string;
}

export const PopoverHeader = ({
  showDismiss = true,
  dismissLabel,
  onDismissClick,
  popoverDismissStyles,
  extend: propsExtend,
  children,
  ...boxProps
}: PopoverHeaderProps) => {
  const closeLabel = dismissLabel
    ? dismissLabel
    : `Close ${children ? children + ' ' : ''}popover`;

  const extend: Extend = ({ theme }) => ({
    paddingTop: `${
      theme.space[3] +
      (showDismiss && children
        ? theme.space[3]
        : showDismiss
          ? theme.space[2]
          : 0)
    }px`,
    ...(propsExtend ? propsExtend({ theme }) : {}),
  });
  return (
    <Box width="100%" px="space4" mb={-2} extend={extend} {...boxProps}>
      {children && (
        <Text
          as="h2"
          fontSize="100"
          fontFamily="heading"
          fontWeight="semiBold"
          lineHeight="heading"
          color="text"
        >
          {children}
        </Text>
      )}
      {showDismiss && (
        <PopoverDismiss>
          <Clickable
            aria-label={closeLabel}
            position="absolute"
            top={0}
            right={0}
            p="space2"
            zIndex={1}
            onClick={onDismissClick}
            extend={popoverDismissStyles}
          >
            <CrossIcon size={3} />
          </Clickable>
        </PopoverDismiss>
      )}
    </Box>
  );
};
