import { useUser, getIsMemberLite } from '@mentimeter/user';
import type {
  UserFeaturesType,
  UserFeaturesTypeEnum,
} from '@mentimeter/http-clients';

export type AccessLevel = 'not-in-plan' | 'not-in-role' | 'has-access';

enum UserFeaturesTypeLevel {
  REGISTERED = 0,
  BASIC = 1,
  PRO = 2,
  ENTERPRISE_TRIAL = 3,
  ENTERPRISE = 4,
}

export function useFeatureAccess(
  requiredUserFeatureType: UserFeaturesTypeEnum,
): AccessLevel {
  const { user } = useUser();

  const isMemberLite = getIsMemberLite(user);
  return getUserFeatureAccess({
    requiredUserFeatureType,
    userFeatureType: user?.features?.type,
    isMemberLite,
  });
}

export function getUserFeatureAccess({
  requiredUserFeatureType,
  userFeatureType,
  isMemberLite,
}: {
  requiredUserFeatureType: UserFeaturesTypeEnum;
  userFeatureType: UserFeaturesType | undefined;
  isMemberLite: boolean;
}): AccessLevel {
  if (!userFeatureType) return 'not-in-plan';

  const userFeaturesTypeLevel = getEnumFromUserFeaturesType(userFeatureType);
  const requiredFeaturesTypeLevel = getEnumFromUserFeaturesType(
    requiredUserFeatureType,
  );
  if (isMemberLite) {
    if (requiredFeaturesTypeLevel > userFeaturesTypeLevel) {
      return 'not-in-plan';
    } else {
      return 'not-in-role';
    }
  } else if (requiredFeaturesTypeLevel > userFeaturesTypeLevel) {
    return 'not-in-plan';
  }

  return 'has-access';
}

function getEnumFromUserFeaturesType(
  userFeaturesType: UserFeaturesType,
): UserFeaturesTypeLevel {
  switch (userFeaturesType) {
    case 'registered':
      return UserFeaturesTypeLevel.REGISTERED;
    case 'basic':
      return UserFeaturesTypeLevel.BASIC;
    case 'pro':
      return UserFeaturesTypeLevel.PRO;
    case 'enterprise':
      return UserFeaturesTypeLevel.ENTERPRISE;
    case 'enterprise_trial':
      return UserFeaturesTypeLevel.ENTERPRISE_TRIAL;
  }
}
