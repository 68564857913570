export enum TrackingContext {
  AddLicensesPage = 'Add licenses page',
  AiMentiBuilder = 'AI Menti Builder',
  Billing = 'Billing page',
  ChangePlanPage = 'Change plan page',
  Editview = 'Edit view',
  EngagementLimits = 'Engagement limits',
  EngagementLimitsPlansModal = 'Engagement limits plans modal',
  ExportMembers = 'Export members',
  FindAvailableTeams = 'Find available teams',
  InviteMembers = 'Invite members',
  ManageMembers = 'Manage members',
  ManageMembersActionList = 'Manage members action list',
  Mentimote = 'Mentimote',
  MsTeams = 'MsTeams',
  MyPresentations = 'Homeview',
  Onboarding = 'Onboarding',
  OverviewPanel = 'OverviewPanel',
  PresentationSharedView = 'Shared presview',
  PresentationTeamInvite = 'Presentation team invite',
  Presentationview = 'Presentation view',
  RequestedByUser = 'Requested by user',
  Results = 'Results view',
  Settings = 'Settings page',
  SharedTemplates = 'Shared templates',
  SharedWithMe = 'Shared with me',
  TemplatesCategoryPage = 'Templates category page',
  TemplatesOverviewPage = 'Templates overview page',
  ThemesPage = 'Themes page',
  Trash = 'Trash',
  Tutorials = 'Tutorials',
  UserHome = 'User home',
  UserHomePopularFeaturesWidget = 'User home popular features widget',
  VerifyEmail = 'Verify email page',
  VerifyEmailLink = 'Verify email link page',
  WelcomePage = 'Welcome Page',
  WorkspacePresentations = 'Shared presentations',
  WorkspaceSettings = 'Team Management View',
}
